import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    title='Alina Infotech-Contact'
  constructor(private titleservice:Title,private meta:Meta) { }

  ngOnInit(): void {
    this.titleservice.setTitle(this.title)
    this.meta.addTag({name:'author', content:'rsgitech'})
    this.meta.addTag({name:'robot', content:'index,follow'})
    this.meta.updateTag({name:'keyword',content:'Alina Infotech,Contact-Us,Alina Infotech Address,Alina Infotech Email'})
    this.meta.updateTag({name:'description',content:'Contains all the information to help people reach out to alina.'})
  }

}

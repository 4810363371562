<!-- ERP Into Section Start -->
<section class="bg-half-100 d-table w-100" style="background: url('../../../../assets/img/background.svg') center center" id="home">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="title-heading mt-4">

                    <h2 class="heading mb-3"> <span class="text-primary">Here With Us,</span> We Manage IT Differerently   </h2>
                    <p class="para-desc">“We strive to delivery industry-leading IT hardware and software solution to the world at its
                        best affordability and convenient.”</p>
                    <div class="mt-4 pt-2">
                        <a href="javascript:void(0)" class="btn btn-pills btn-primary m-1">Read More..</a>
                         </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <img src="../../../../assets/img/brainstorm.png" class="img-fluid" alt="">
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section>


<section class="about-section ">
    <div class="container" id="here-we-begin">
        <div class="row">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="about-image">
                    <img src="../../../../assets/img/development copy.png" alt="image" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-tab">
                    <h2 style="color: #2596be;">And Here's How It All Started</h2>
                    <div class="bar"></div>
                    <p id="all-started" class="para-desc">
                        Alina Infotech and electronics (Formerly Known As) was founded in year 2007 by a young group
                        of individuals with an aim to manage and deliver IT industry differently. We majorly focus on
                        people problems in the field of technology so we innovate unique solution to handle those
                        problems smartly. we have been delivering industry-leading IT hardware and software solution to
                        all segment of consumer. We have very strong position in Nepal and building the same globally.
                        Later on, on 2020 Alina Infotech and Electronics was converted to Alina Infotech Pvt. Ltd.
                        Currently Alina Infotech Have Three Different Branches Located in Biratnagar (HO), Itahari And
                        Kathmandu.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="for-layer">
    <div class="parallax">
        <h2 style="padding: 2rem; border: 6px solid #f1f1f1; color: white;">Strengthen Your Goal With Our Experties</h2>
    </div>
</div>
<!-- project and achievement .. start-->
<div class="container">
    <div class="row-fluid-wrapper row-depth-1 row-number-8 ">
        <div class="row-fluid ">
            <div class="span12 widget-span widget-type-cell centered w1200" style="margin-top: 20px;"
                data-widget-type="cell" data-x="0" data-w="12">

                <div class="row-fluid-wrapper row-depth-1 row-number-9 ">
                    <div class="row-fluid ">
                        <div class="span12 widget-span widget-type-rich_text section-intro tc"
                            data-widget-type="rich_text" data-x="0" data-w="12">
                            <div class="cell-wrapper layout-widget-wrapper">
                                <span id="hs_cos_wrapper_module_1497992508648437"
                                    class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"
                                    data-hs-cos-general-type="widget" data-hs-cos-type="rich_text">
                                    <h2 style="margin-bottom: 0 !important">Areas of Experties</h2>
                                    <h5 style="color: #2c96be;">What you get joining us ...</h5>
                                </span>
                            </div>
                            <!--end layout-widget-wrapper -->
                        </div>
                        <!--end widget-span -->
                    </div>
                    <!--end row-->
                </div>
                <!--end row-wrapper -->
                <section class="section">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-6">
                                <div class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                                    <div class="icons text-center">
                                        <img src="../../../../assets/img/hardware.png" alt="hardware" id="about-image">
                                    </div>
                                    <div class="card-body p-0 mt-4">
                                        <a href="javascript:void(0)" class="title h5 text-dark"> Hardware Components</a>
                                        <p class="text-muted mt-2">We offer servers, computers, computer accessories and services by sourcing from local market and from international market as well.</p>


                                    </div>
                                </div>
                            </div><!--end col-->

                            <div class="col-lg-3 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                                    <div class="icons text-center">
                                        <img src="../../../../assets/img/tools.png" alt="hardware" id="about-image">
                                    </div>
                                    <div class="card-body p-0 mt-4">
                                        <a href="javascript:void(0)" class="title h5 text-dark">Maintenance and Support</a>
                                        <p class="text-muted mt-2">We offer all kinds of maintainance and support required according to the need. It includes PC's, AC's, Servers and so on.</p>

                                        <i class="uil uil-heart full-img"></i>
                                    </div>
                                </div>
                            </div><!--end col-->

                            <div class="col-lg-3 col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                <div class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                                    <div class="icons text-center">
                                        <img src="../../../../assets/img/buisness.png" alt="buisness" id="about-image">
                                    </div>
                                    <div class="card-body p-0 mt-4">
                                        <a href="javascript:void(0)" class="title h5 text-dark"> Business Reengineering</a>
                                        <p class="text-muted mt-2">We help in business management strategy to recreate a core business process to improve output, quality and to reduce costs.</p>

                                        <i class="uil uil-eye full-img"></i>
                                    </div>
                                </div>
                            </div><!--end col-->

                            <div class="col-lg-3 col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                <div class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                                    <div class="icons text-center">
                                        <img src="../../../../assets/img/networking-monitor-svgrepo-com.svg" alt="network" id="about-image">
                                    </div>
                                    <div class="card-body p-0 mt-4">
                                        <a href="javascript:void(0)" class="title h5 text-dark">Network Monitoring And Management</a>
                                        <p class="text-muted mt-2">We offer you the assistment over your network components like router, switches ans so on.</p>

                                        <i class="uil uil-expand-arrows full-img"></i>
                                    </div>
                                </div>
                            </div><!--end col-->

                            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                                <div class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                                    <div class="icons text-center">
                                        <img src="../../../../assets/img/enterprise.png" alt="enterprise" id="about-image">
                                    </div>
                                    <div class="card-body p-0 mt-4">
                                        <a href="javascript:void(0)" class="title h5 text-dark"> Enterprise Server & Storage Solution</a>
                                        <p class="text-muted mt-2">We offer you an ideal solution for Enterprise Resource Planning with server and storage.</p>

                                        <i class="uil uil-layer-group full-img"></i>
                                    </div>
                                </div>
                            </div><!--end col-->

                            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                                <div class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                                    <div class="icons text-center">
                                        <img src="../../../../assets/img/software.png" alt="software" id="about-image">
                                    </div>
                                    <div class="card-body p-0 mt-4">
                                        <a href="javascript:void(0)" class="title h5 text-dark">Customized Software Development</a>
                                        <p class="text-muted mt-2">With our strong grip in software, we offer you a great flexiblity in the development of softwares.</p>

                                        <i class="uil uil-airplay full-img"></i>
                                    </div>
                                </div>
                            </div><!--end col-->

                            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                                <div class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                                    <div class="icons text-center">
                                        <img src="../../../../assets/img/web.png" alt="web" id="about-image">
                                    </div>
                                    <div class="card-body p-0 mt-4">
                                        <a href="javascript:void(0)" class="title h5 text-dark">Web Based Support & Solution Development</a>
                                        <p class="text-muted mt-2">With our full fledged IT support we hold on solving and supporting your Web based platform or business.</p>

                                        <i class="uil uil-focus-target full-img"></i>
                                    </div>
                                </div>
                            </div><!--end col-->

                            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                                <div class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow">
                                    <div class="icons text-center">
                                        <img src="../../../../assets/img/idea.png" alt="automation" id="about-image">
                                    </div>
                                    <div class="card-body p-0 mt-4">
                                        <a href="javascript:void(0)" class="title h5 text-dark">Overall Automation Consultancy</a>
                                        <p class="text-muted mt-2">We offer you automation solutions provider for diverse sectors. Reach us and experience excellence.</p>

                                        <i class="uil uil-analytics full-img"></i>
                                    </div>
                                </div>
                            </div><!--end col-->
                        </div><!--end row-->
                    </div><!--end container-->





                </section>

                <!--end row-wrapper -->

                <div class="row-fluid-wrapper row-depth-1 row-number-1 ">
                    <div class="row-fluid features-align-evenly">
                        <div class="span3 widget-span widget-type-cell hns tc sm-mb40" data-widget-type="cell"
                            data-x="0" data-w="3">

                            <div class="row-fluid-wrapper row-depth-1 row-number-2 ">
                                <div class="row-fluid ">
                                    <div class="span12 widget-span widget-type-custom_widget "
                                        data-widget-type="custom_widget" data-x="0" data-w="12">
                                        <div id="hs_cos_wrapper_module_15303510364251535"
                                            class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                                            data-hs-cos-general-type="widget" data-hs-cos-type="module">
                                            <div class="icon-dot theme large ">

                                                <i class="far fa-building" aria-hidden="true"></i>

                                            </div>
                                        </div>

                                    </div>
                                    <!--end widget-span -->
                                </div>
                                <!--end row-->
                            </div>
                            <!--end row-wrapper -->

                            <div class="row-fluid-wrapper row-depth-1 row-number-3 ">
                                <div class="row-fluid ">
                                    <div class="span12 widget-span widget-type-rich_text "
                                        data-widget-type="rich_text" data-x="0" data-w="12">
                                        <div class="cell-wrapper layout-widget-wrapper">
                                            <span id="hs_cos_wrapper_module_1497992439167416"
                                                class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"
                                                 data-hs-cos-general-type="widget" data-hs-cos-type="rich_text">
                                                <img src="../../../../assets/img/activity.svg" salt="image">
                                                <h2 style="margin-top: 20px !important;">+500</h2>
                                                <p>Completed Project</p>
                                            </span>
                                        </div>
                                        <!--end layout-widget-wrapper -->
                                    </div>
                                    <!--end widget-span -->
                                </div>
                                <!--end row-->
                            </div>
                            <!--end row-wrapper -->

                        </div>
                        <!--end widget-span -->
                        <div class="span3 widget-span widget-type-cell hns tc sm-mb40"  data-widget-type="cell"
                            data-x="3" data-w="3">

                            <div class="row-fluid-wrapper row-depth-1 row-number-4 ">
                                <div class="row-fluid ">
                                    <div class="span12 widget-span widget-type-custom_widget "
                                        data-widget-type="custom_widget" data-x="0" data-w="12">
                                        <div id="hs_cos_wrapper_module_15303510789811540"
                                            class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                                            data-hs-cos-general-type="widget" data-hs-cos-type="module">
                                            <div class="icon-dot theme large ">

                                                <i class="fas fa-globe" aria-hidden="true"></i>

                                            </div>
                                        </div>

                                    </div>
                                    <!--end widget-span -->
                                </div>
                                <!--end row-->
                            </div>
                            <!--end row-wrapper -->

                            <div class="row-fluid-wrapper row-depth-1 row-number-5 ">
                                <div class="row-fluid ">
                                    <div class="span12 widget-span widget-type-rich_text "
                                        data-widget-type="rich_text" data-x="0" data-w="12">
                                        <div class="cell-wrapper layout-widget-wrapper">
                                            <span id="hs_cos_wrapper_module_1497992500232433"
                                                class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"
                                                 data-hs-cos-general-type="widget" data-hs-cos-type="rich_text">
                                                <img src="../../../../assets/img/globe.svg" alt="image">
                                                <h2 style="margin-top: 20px !important;">+15 Years</h2>
                                                <p>Of Experience</p>
                                            </span>
                                        </div>
                                        <!--end layout-widget-wrapper -->
                                    </div>
                                    <!--end widget-span -->
                                </div>
                                <!--end row-->
                            </div>
                            <!--end row-wrapper -->

                        </div>
                        <!--end widget-span -->
                        <div class="span3 widget-span widget-type-cell hns tc"  data-widget-type="cell" data-x="6"
                            data-w="3">

                            <div class="row-fluid-wrapper row-depth-1 row-number-6 ">
                                <div class="row-fluid ">
                                    <div class="span12 widget-span widget-type-custom_widget "
                                        data-widget-type="custom_widget" data-x="0" data-w="12">
                                        <div id="hs_cos_wrapper_module_15303511019941545"
                                            class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                                            data-hs-cos-general-type="widget" data-hs-cos-type="module">
                                            <div class="icon-dot theme large ">

                                                <i class="fas fa-user-astronaut" aria-hidden="true"></i>

                                            </div>
                                        </div>

                                    </div>
                                    <!--end widget-span -->
                                </div>
                                <!--end row-->
                            </div>
                            <!--end row-wrapper -->

                            <div class="row-fluid-wrapper row-depth-1 row-number-7 ">
                                <div class="row-fluid ">
                                    <div class="span12 widget-span widget-type-rich_text "
                                        data-widget-type="rich_text" data-x="0" data-w="12">
                                        <div class="cell-wrapper layout-widget-wrapper">
                                            <span id="hs_cos_wrapper_module_1497992497318429"
                                                class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"
                                                 data-hs-cos-general-type="widget" data-hs-cos-type="rich_text">
                                                <img src="../../../../assets/img/user-plus.svg" salt="image">
                                                <h2 style="margin-top: 20px !important;">+250</h2>
                                                <p>Professional Employee's</p>
                                            </span>
                                        </div>
                                        <!--end layout-widget-wrapper -->
                                    </div>
                                    <!--end widget-span -->
                                </div>
                                <!--end row-->
                            </div>
                            <!--end row-wrapper -->

                        </div>
                        <!--end widget-span -->
                        <div class="span3 widget-span widget-type-cell hns tc"  data-widget-type="cell" data-x="9"
                            data-w="3">

                            <div class="row-fluid-wrapper row-depth-1 row-number-8 ">
                                <div class="row-fluid ">
                                    <div class="span12 widget-span widget-type-custom_widget "
                                        data-widget-type="custom_widget" data-x="0" data-w="12">
                                        <div id="hs_cos_wrapper_module_1575902341662598"
                                            class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                                            data-hs-cos-general-type="widget" data-hs-cos-type="module">
                                            <div class="icon-dot theme large ">

                                                <i class="fas fa-satellite-dish" aria-hidden="true"></i>

                                            </div>
                                        </div>

                                    </div>
                                    <!--end widget-span -->
                                </div>
                                <!--end row-->
                            </div>
                            <!--end row-wrapper -->

                            <div class="row-fluid-wrapper row-depth-1 row-number-9 ">
                                <div class="row-fluid ">
                                    <div class="span12 widget-span widget-type-rich_text "
                                        data-widget-type="rich_text" data-x="0" data-w="12">
                                        <div class="cell-wrapper layout-widget-wrapper">
                                            <span id="hs_cos_wrapper_module_1575902341662599"
                                                class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"
                                                 data-hs-cos-general-type="widget" data-hs-cos-type="rich_text">
                                                <img src="../../../../assets/img/smile.svg" salt="image" style="height: 55px;">
                                                <h2 style="margin-top: 20px !important;">98%</h2>
                                                <p>Customer Satisfaction</p>
                                            </span>
                                        </div>
                                        <!--end layout-widget-wrapper -->
                                    </div>
                                    <!--end widget-span -->
                                </div>
                                <!--end row-->
                            </div>
                            <!--end row-wrapper -->

                        </div>
                        <!--end widget-span -->
                    </div>
                    <!--end row-->
                </div>
                <!--end row-wrapper -->

            </div>
            <!--end widget-span -->
        </div>
        <!--end row-->
    </div>
</div>


<!-- project and achievement .. end-->

<!-- clients details -->
<div class="for-layer">
    <div class="parallax1">
        <h2 style="padding: 2rem; border: 6px solid #f1f1f1; color: white;">Customers Satisfaction's, Our Motivation</h2>
    </div>
</div>

<div class="container" style="margin-top: 2rem; margin-bottom: 2rem;">
    <div class="row justify-content-center">
        <div class="col-12 text-center">
            <div class="section-title ">
                <h2 class="title mb-4" style="color: #2596be;">Our Valueable Clients</h2>
                <p class="text-muted para-desc mx-auto mb-0"> <span class="text-primary fw-bold">Alina Infotech Pvt. Ltd.</span>  Deals with various wide range of IT related Products and Services under
                    reasonable price. Since the start of our service, we are actively providing our products and service
                    in different sectors like retail, corporates, government office, NGO’s, INGO’s etc. Some of the
                    Products and Services we deal with are as below</p>
            </div>
        </div><!--end col-->
    </div><!--end row-->
    <div class="row align-items-center">
        <div class="col-lg-12 col-md-12" style="display: flex; justify-content: space-around">
           
            <img src="../../../../assets/img/about/unilever.png" alt="" class="clients-img">
           
            <img src="../../../../assets/img/about/aarti steel.jpeg" alt="" class="clients-img">
            <img src="../../../../assets/img/about/Asian Thiafoods.png" alt="" class="clients-img">
         
            <img src="../../../../assets/img/about/kathmandu_metro.png" alt="" class="clients-img">
            <img src="../../../../assets/img/about/kumaribank.png" alt="" class="clients-img">
            <img src="../../../../assets/img/about/ministry of culture.jpeg" alt="" class="clients-img">
            <img src="../../../../assets/img/about/Nepal_Police_logo.png" alt="" class="clients-img">
            
        </div>
      

        <div class="col-lg-12 col-md-12" style="display: flex; justify-content: space-around">
           
            <img src="../../../../assets/img/about/plan.png" alt="" class="clients-img">
            <img src="../../../../assets/img/about/Purbanchal_University_Logo.png" alt="" class="clients-img">
            <img src="../../../../assets/img/about/Tribhuvan_University_logo.svg.png" alt="" class="clients-img">
            <img src="../../../../assets/img/about/UNICEF-latest-logo.jpg" alt="" class="clients-img">
            <img src="../../../../assets/img/about/world-vision-logo.png" alt="" class="clients-img">
            <img src="../../../../assets/img/about/FNCCI Logo.jpg" alt="" class="clients-img">
            <img src="../../../../assets/img/about/unilever.png" alt="" class="clients-img">
        </div>

    </div>

</div>


<section class="digital-section pt-100">
    <!-- <div class="container headerbox position-relative"> -->
    <div class="d-flex gap-5">
        <div class="d-flex align-items-center">
            <div class="digital-image">
                <img src="assets/img/digital.png" alt="image" />
            </div>
        </div>
        <div>
            <div class="digital-content">
                <h3 id="why-work">Why <span>work with Alina ?</span></h3>
                <div class="bar"></div>

                <b>
                    <p id="career-p">
                        Want to become part of a winning team with
                        opportunities for growth, career advancement and
                        development? Alina Infotech is proud to provide an
                        environment where we support you and your drive for
                        success.We have a passionate commitment to learning
                        and
                        investing in our associates looking to accelerate their careers.
                        We offer a wide range of full-time and part-time employment
                        opportunities for professionals and entry level candidates who
                        share our core values.
                    </p>
                </b>
                <ul class="digital-list ">
                    <div class="row">


                        <div class="col-lg-6 col-xl-6 col-md-6  col-12 mt-4 pt-2">
                            <div
                                class="box1 d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                                <div class="icon text-center rounded-circle me-3">
                                    <img src="../../assets/img/check-circle.svg" alt="market">
                                </div>
                                <div class="flex-0 ms-0">
                                    <h4 class="title mb-0 text-dark">
                                        Market Oriented Pay
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <!---end-->

                        <div class="col-lg-6 col-xl-6 col-md-6 col-12 mt-4 pt-2">
                            <div
                                class="box1 d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                                <div class="icon text-center rounded-circle me-3">
                                    <img src="../../assets/img/zap.svg" alt="performance">
                                </div>
                                <div class="flex-0 ms-0">
                                    <h4 class="title mb-0 text-dark">
                                        Performance Incentives
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <!--div end-->

                        <div class="col-lg-6 col-xl-6 col-md-6 col-12 mt-4 pt-2">
                            <div
                                class="box1 d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                                <div class="icon text-center rounded-circle me-3">
                                    <img src="../../assets/img/dollar-sign.svg" alt="bonus">
                                </div>
                                <div class="flex-0 ms-0">
                                    <h4 class="title mb-0 text-dark">
                                        Festival Bonus
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <!---div ends-->
                        <div class="col-lg-6 col-xl-6 col-md-6 col-12 mt-4 pt-2">
                            <div
                                class="box1 d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                                <div class="icon text-center rounded-circle me-3">
                                    <img src="../../assets/img/target.svg" alt="team">
                                </div>
                                <div class="flex-0 ms-0">
                                    <h4 class="title mb-0 text-dark">
                                        Team Building Practise
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <!--div end-->
                        <div class="col-lg-6 col-xl-6 col-md-6 col-12 mt-4 pt-2">
                            <div
                                class="box1 d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                                <div class="icon text-center rounded-circle me-3">
                                    <img src="../../assets/img/user (4).svg" alt="employee">
                                </div>
                                <div class="flex-0 ms-0">
                                    <h4 class="title mb-0 text-dark">
                                        Employee Asistance Program
                                    </h4>
                                </div>
                            </div>
                        </div>

                        <!---Start-->
                        <div class="col-lg-6 col-xl-6 col-md-6 col-12 mt-4 pt-2">
                            <div
                                class="box1 d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                                <div class="icon text-center rounded-circle me-3">
                                    <img src="../../assets/img/award.svg" alt="award">
                                </div>
                                <div class="flex-0 ms-0">
                                    <h4 class="title mb-0 text-dark">
                                        Recognition and Awards
                                    </h4>
                                </div>
                            </div>
                        </div>

                        <!---End -->

                        <!--div end-->
                    </div>
                </ul>
                <!--digitallist end-->
            </div>
        </div>
    </div>
    <!-- </div> -->
</section>
<section class="choose-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Roles <span>offered</span></h2>
            <p>
                Alina Infotech offers different roles and positions for skilled
                candidates paving their way into sucessful future .<br>Lets work
                together.
            </p>
            <div class="bar"></div>
        </div>
        <!---list of features starts-->

        <!--list of features ends-->
    </div>
</section>
<div class="container career-container">
    <div class="left-container">
        <div class="row flex-column">
            <div class="col-8 mt-4 pt-2">
                <!-- <a href=""> -->
                <div class="firstbox d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                    <img src="../../assets/img/codepen.svg" class="avatar avatar-ex-sm" alt="image" />
                    <div class="flex-1 ms-3">
                        <h4 class="title mb-0 text-dark">Software Engineer</h4>

                    </div>
                </div>
                <!-- </a> -->
            </div>
            <!--div starts -->

            <div class="col-8 mt-4 pt-2">
                <a href="">
                    <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                        <img src="../../assets/img/grid.svg" class="avatar avatar-ex-sm" alt="" />
                        <div class="flex-1 ms-3">
                            <h2 class="title mb-0 text-dark">
                              Hardware Expert
                            </h2>

                        </div>
                    </div>
                </a>
            </div>

            <!--end col-->

            <!--div starts-->
            <div class=" col-8 mt-4 pt-2">
                <a href="job-detail-one.html">
                    <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                        <img src="../../assets/img/users.svg" class="avatar avatar-ex-sm" alt="sales Representatives" />
                        <div class="flex-1 ms-3">
                            <h4 class="title mb-0 text-dark">Sales Representative</h4>

                        </div>
                    </div>
                </a>
            </div>
            <!---div ends-->
            <!--starts -->
            <div class=" col-8 mt-4 pt-2">
                <a href="">
                    <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                        <img src="../../assets/img/machinelearning .png" class="avatar avatar-ex-sm" alt="" />
                        <div class="flex-1 ms-3">
                            <h4 class="title mb-0 text-dark">
                               Buisness Development Associate
                            </h4>

                        </div>
                    </div>
                </a>
            </div>

            <div class=" col-8 mt-4 pt-2">
                <a href="">
                    <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                        <img src="../../assets/img/development.png" class="avatar avatar-ex-sm" alt="" />
                        <div class="flex-1 ms-3">
                            <h2 class="title mb-0 text-dark">
                                Digital Marketing Associates
                            </h2>

                        </div>
                    </div>
                </a>
            </div>

            <!--ends -->
        </div>
    </div>

    <div class="right-container">
        <img src="../../assets/img/app_development_SVG.svg" class="avatar avatar-ex-sm" alt="image" />
    </div>

</div>

<section class="bg-half-100 d-table w-100" style="background: url('../../../../assets/img/background.svg') center center" id="home">


<!-- contact section start -->
<section class="pt-5 w-100 h-100">
    <div class="headerbox position-relative">
        <div class="container">
            <!-- contain -->
            <div
                class="contain position-relative text-xl-left text-md-center text-left"
            >
                <div class="section-title">
                    <h2>Contact Us</h2>
                </div>
                <!-- <h2>Alina Infotech ERP</h2> -->
                <p class="mb-0">
                    Depending on whether you are a client wanting to inquire about our services,
                        a special talent applying for a job, or a site visitor wanting to offer your feedback,
                         the different contact options below will help you get in touch with Alina Infotech,
                         fast and efficiently.
                </p>
                <img
                    src="https://www.gnhindia.com/wp-content/uploads/2021/11/contact-banner.svg"
                    class="img-fluid position-absolute float-img d-xl-block d-none"
                    alt="man"
                />
            </div>
            <!-- Shapes -->
            <div class="shapes position-absolute w-100 h-100">
                <div class="shape shape-1"></div>
                <div class="shape shape-2"></div>
                <div class="shape shape-3"></div>
                <div class="shape shape-4"></div>
            </div>
        </div>
    </div>
</section>


<section class="section bg-light">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-12">
                <div class="card features feature-primary feature-full-bg rounded p-4 position-relative overflow-hidden border-0">
                    <span class="h1 icon-color">
                        <i class="uil uil-newspaper"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Corporate Office</h5>
                        <p class="para text-muted mb-0">Newroad Complex, New Road, Kathmandu</p>

                        <a href="javascript:void(0)" class="btn btn-pills btn-soft-success mt-4" data-bs-toggle="modal" data-bs-target="#appointment-request">+977-1-5911166</a>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="card features feature-primary feature-full-bg rounded p-4 position-relative overflow-hidden border-0">
                    <span class="h1 icon-color">
                        <i class="uil uil-clock"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Biratnagar Branch</h5>

                        <h6 class="mb-0">Biratnagar -07, Mainroad</h6>
                        <a href="javascript:void(0)" class="btn btn-pills btn-soft-success mt-4" data-bs-toggle="modal" data-bs-target="#appointment-request">+977-21576716 , 514878</a>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="card features feature-primary feature-full-bg rounded p-4 position-relative overflow-hidden border-0">
                    <span class="h1 icon-color">
                        <i class="uil uil-clock"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Itahari Branch</h5>

                        <h6 class="mb-0">Fulbari Market,Itahari</h6>
                        <a href="javascript:void(0)" class="btn btn-pills btn-soft-success mt-4" data-bs-toggle="modal" data-bs-target="#appointment-request"> +977-25-586595/586585</a>
                    </div>
                </div>
            </div>



        </div><!--end row-->
    </div><!--end container-->
</section>



import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
    title='Alina Infotech-services'
  constructor(private titleservice:Title,private meta:Meta) { }

  ngOnInit(): void {
    this.titleservice.setTitle(this.title)
    this.meta.addTag({name:'author', content:'rsgitech'})
    this.meta.addTag({name:'robot', content:'index,follow'})
    this.meta.updateTag({name:'keyword',content:'Alina Infotech services,Services,Services offered.'})
    this.meta.updateTag({name:'description',content:'Details of different kind of services offered by alina.'})
  }

}

import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    title='Alina Infotech Pvt.ltd'

    data = [{
        "id": 1,
        "title": "Realize Your Software Vision",
        "description":"Application Development | Software Engineering | Systems Integration",
        "link1":"",
        "link2":"",
        "image": "assets/img/banner/banner01.png"
      },
      {
        "id": 2,
        "title": "Convert your thoughts in to Mobile App",
        "description":"Robust and experiential mobile solutions that help you grow revenue and boost brand engagement",
        "link1":"sth.com",
        "link2":"",
        "image": "assets/img/banner/banner02.jpg"
      },
      {
        "id": 3,
        "title": "Enterprise Software Development",
        "description":"Enhancing business agility and efficiency with cutting-edge custom software , web apps and mobile solutions",
        "link1":"sth.com",
        "link2":"",
        "image": "assets/img/banner/banner04.jpg"
      },
      {
        "id": 4,
        "title": "Outsourcing Software Development",
        "description":"We are an  IT consulting & outsourcing Company offering custom software development, web / mobile app development & dedicated software teams.",
        "link1":"sth.com",
        "link2":"",
        "image": "assets/img/banner/banner05.jpg"
      }
    ];
  constructor(private titleservice:Title,private meta:Meta) { }

  ngOnInit(): void {
    this.preloadImages();
    this.titleservice.setTitle(this.title)
    this.meta.addTag({name:'author', content:'rsgitech'})
    this.meta.addTag({name:'robot', content:'index,follow'})
    this.meta.updateTag({name:'keyword',content:'Alina Infotech,Alina Infotech ERP,Alina Infotech Software'})
    this.meta.updateTag({name:'description',content:'Services provided by alina,its history,reasons to select alina,details of alina,technologies used'})
  }

  currentIndex = 0;
  slides = [
    {image: '../../../../assets/img/developer-team.svg', description: 'Image 00'},
    {image: '../../../../assets/img/social.svg', description: 'Image 01'},
    {image: '../../../../assets/img/android-device.svg', description: 'Image 02'},

  ];

  texts = [
    { title:'Erp Development',description: ' Alina Infotech is the perfect partner for updating the operational and financial processes of your business. We serve your complex business challenges with our specific solutions. With ourin-depth knowledge of ERP services, we buildyour desired result into a reality that will help you thrive in this technologically changing world.'},
    {title:'Ecommerce solution for your Buisness',description: ' Take your e-commerce efforts to the next levelto become the best in your niche or start your complete digital transformation. We can help youbecome the leading online business in your segment, improve existing workflow, analyzevisitor shopping patterns and the value of digital business presence with your customers'},
    { title:'Mobile App Development',description: 'We have been building and building great mobileapps for over a decade and we are proud of our approach. We put the same effort into everythingwe do, from integrated ERP experiences to educational apps that teach on the go. With hundreds of mobile applications under our beltwe can speak iOS and Android, Flutter and hybriddevelopment as the first language.'},

  ]



  preloadImages() {
    this.slides.forEach(slide => {
      (new Image()).src = slide.image;
    });

    this.texts.forEach(text => {
        (new Text()).textContent = text.description
    });
  }

  setCurrentSlideIndex(index) {
    this.currentIndex = index;
  }

  isCurrentSlideIndex(index) {
    return this.currentIndex === index;
  }

  isCurrentTextIndex(index) {
    return this.currentIndex === index;
  }

  prevSlide() {
    this.currentIndex = (this.currentIndex < this.slides.length - 1) ? ++this.currentIndex : 0;
  }

  nextSlide() {
    this.currentIndex = (this.currentIndex > 0) ? --this.currentIndex : this.slides.length - 1;
  }

}

<!-- Navbar Start -->
<header id="topnav" class="defaultscroll sticky">
    <div class="container">
        <!-- Logo container-->
        <a class="logo" href="index.html">
            <img
                src="assets/img/logo.png"
                height="60"
                width="200"
                class="logo-light-mode"
                alt=""
            />
            <img
                src="assets/img/logo.png"
                height="60"
                width="200"
                class="logo-dark-mode"
                alt=""
            />
        </a>

        <!-- Logo End -->

        <!-- End Logo container-->
        <div class="menu-extras">
            <div class="menu-item">
                <!-- Mobile menu toggle-->
                <a class="navbar-toggle" id="isToggle" onclick="toggleMenu()">
                    <div class="lines">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </a>
                <!-- End mobile menu toggle-->
            </div>
        </div>

        <!--Login button Start-->
        <ul class="buy-button list-inline mb-0">
            <li class="list-inline-item mb-0 pe-1">
                <div class="dropdown">
                    <button
                        type="button"
                        class="btn dropdown-toggle p-0"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <!-- <i class="uil uil-search text-white title-dark btn-icon-light fs-5 align-middle"></i>
                        <i class="uil uil-search text-dark btn-icon-dark fs-5 align-middle"></i> -->
                        <i
                            class="uil uil-search text-dark fs-5 align-middle"
                        ></i>
                    </button>
                    <div
                        class="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 p-0"
                        style="width: 300px"
                    >
                        <div class="search-bar">
                            <div id="itemSearch" class="menu-search mb-0">
                                <form
                                    role="search"
                                    method="get"
                                    id="searchItemform"
                                    class="searchform"
                                >
                                    <input
                                        type="text"
                                        class="form-control border rounded"
                                        name="s"
                                        id="searchItem"
                                        placeholder="Search..."
                                    />
                                    <input
                                        type="submit"
                                        id="searchItemsubmit"
                                        value="Search"
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </li>

            <li class="list-inline-item mb-0 marginRight">
                <a
                    href="https://www.facebook.com/Alinainfotech/"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                >
                    <div class="btn btn-icon btn-pills btn-soft-primary">
                        <img
                            src="../../../../assets/img/facebook (3).svg"
                            alt="facebook"
                        />
                    </div>
                </a>
            </li>

            <li class="list-inline-item mb-0">
                <a
                    href="https://www.twittercom/Alinainfotech/"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                >
                    <div class="btn btn-icon btn-pills btn-soft-primary">
                        <img
                            src="../../../../assets/img/twitter.svg"
                            alt="twitter"
                        />
                    </div>
                </a>
            </li>

            <li class="list-inline-item ps-1 mb-0">
                <a href="https://1.envato.market/landrick" target="_blank">
                    <div class="btn btn-icon btn-pills btn-soft-primary">
                        <img
                            src="../../../../assets/img/tiktok-logo-4500.svg"
                            alt="tiktok"
                            width="22px"
                        />
                    </div>
                </a>
            </li>
        </ul>
        <!--Login button End-->

        <div id="navigation">
            <!-- Navigation Menu-->

            <ul class="navigation-menu">
                <li class="sub-menu-item">
                    <a routerLink="Home" class="nav-link">Home </a>
                </li>

                <li
                    class="sub-menu-item"
                    data-bs-toggle="collapse"
                    data-target=".navbar-collapse.show"
                >
                    <a
                        routerLink="/about"
                        class="nav-link"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >About</a
                    >
                </li>
                <li class="sub-menu-item">
                    <a routerLink="/services" class="nav-link">Services </a>
                    <!-- <i class='bx bx-chevron-down'></i> -->
                    <!-- <ul class="dropdown-menu"> -->
                    <!-- <li class="sub-menu-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services</a></li> -->
                    <!-- <li class="sub-menu-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/services-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Two</a></li>
                        <li class="sub-menu-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/services-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Details</a></li> -->
                    <!-- </ul> -->
                </li>
                <li
                    class="sub-menu-item"
                    data-bs-toggle="collapse"
                    data-target=".navbar-collapse.show"
                >
                    <a
                        routerLink="/contact"
                        class="nav-link"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Contact</a
                    >
                </li>
                <li
                    class="sub-menu-item"
                    data-bs-toggle="collapse"
                    data-target=".navbar-collapse.show"
                >
                    <a
                        routerLink="/careers"
                        class="nav-link"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        >Carrers</a
                    >
                </li>
            </ul>

            <!--end navigation menu-->
        </div>
        <!--end navigation-->
    </div>
    <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->

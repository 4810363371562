import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
title='Alina Infotech-About'
  constructor(private titleservice:Title,private meta:Meta) { }

  ngOnInit(): void {
    this.titleservice.setTitle(this.title)
    this.meta.addTag({name:'author', content:'rsgitech'})
    this.meta.addTag({name:'robot', content:'index,follow'})
    this.meta.updateTag({name:'keyword',content:'Alina Infotech,About Alina Infotech,client reviews'})
    this.meta.updateTag({name:'description',content:'Description of Alina Infotech Company,our clients opinion of us and frequently asked question'})
  }

}

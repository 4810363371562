<!-- <app-navbar-one></app-navbar-one> -->
<section class="bg-half-100 d-table w-100" style="background: url('../../../../assets/img/background.svg') center center" id="home">
    <div class="headerbox position-relative">
        <div class="container">
            <!-- contain -->
            <div
                class="contain position-relative text-xl-left text-md-center text-left"
            >
                <h1 class="mb-4 text-uppercase about pt-4">Alina Infotech Services</h1>
                <p class="mb-0">
                    Alina Infotech Pvt. Ltd. Deals with various wide range of IT related Products and Services under reasonable price. Since the start of our service, we are actively providing our products and service in different sectors like retail, corporates, government office, NGO’s, INGO’s etc. Some of the Products and Services we deal with are as below.
                </p>

                <img
                    src="../../../../assets/img/shop.svg"
                    class="img-fluid position-absolute float-img d-xl-block d-none"
                    alt="man"
                />
            </div>
            <!-- Shapes -->
            <div class="shapes position-absolute w-100 h-100">
                <div class="shape shape-1"></div>
                <div class="shape shape-2"></div>
                <div class="shape shape-3"></div>
                <div class="shape shape-4"></div>
            </div>
        </div>
    </div>
</section>




<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="card shadow rounded border-0">
                    <div class="card-body">

                        <h5 class="card-title">ICT CONSULTANCY</h5>
                        <p class="text-muted">We consult for high-end technology implementation at clients’ site or implementation of new
                            technology according to the clients’ requirement or upgrading, enhancing the existing facilities in the
                            clients end with the new technology integrated with the existing one. Alina Infotech offers a full range
                            of consulting services to help analyze your business requirements for effective implementation of
                            solutions. Our consulting services cover.</p>

                        <ul class="list-unstyled text-muted">
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                Strategy planning
                            </li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                Assessment
                            </li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                Re-engineering solutions
                            </li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                Planning, audits, best practices etc.
                            </li>
                            </ul>



                            <h5 class="card-title">SOFTWARE DEVELOPMENT</h5>
                        <p class="text-muted">Beside the hardware and network solutions; with design and development expertise in diverse
                            platforms, best-of-breed tools and techniques, combined with industry best practices, Alina offers
                            scalable end-to-end application development and management solutions from requirement analysis
                            for deployment and rollout. We are developing software, related to production management,
                            commercial jobs, buying, accounting software for trading, manufacturing house and conglomerates.
                            We hope to come to you with desired software at a reasonable cost. Alina’s services span the
                            following application lifecycle stages.</p>

                        <ul class="list-unstyled text-muted">
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                APPLICATION DEVELOPMENT
                            </li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                APPLICATION MAINTENANCE
                            </li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                APPLICATION SUPPORT
                            </li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                APPLICATION INTEGRATION/MIGRATION/TRANSFORMATION
                            </li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                APPLICATION MANAGEMENT
                            </li>
                            </ul>



                            <h5 class="card-title">NETWORK & INFRASTRUCTURE SERVICES </h5>
                        <p class="text-muted">Growth in the Solution Integration (SI) services market is fueled by the need for seamless business
                            processes across an organization’s complete value chain of customers, partners, suppliers, and
                            employees. Alina's services enable clients to identify, develop, and implement the best-fit solutions
                            which are equipped to meet their changing business requirements.</p>

                        <ul class="list-unstyled text-muted">
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                Leverage IT investments
                            </li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                Minimize risks
                            </li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                Maximize compatibility
                            </li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                Maximize interoperability
                            </li>

                            </ul>


                            <h5 class="card-title"> HARDWARE SALES & SUPPORT SERVICES</h5>
                        <p class="text-muted">We offer servers, computers, computer accessories and services by sourcing from local market and
                            from international market as well. Our team of experts is ready to serve you when you are worried
                            due to lack of confidence in “commitment of service”. You are hereby requested to call us for any
                            kind of requirement of computers, computer parts and services whatever and whenever you need. We
                            deal With Following in hardware sales and suppor.</p>

                        <ul class="list-unstyled text-muted">
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                Laptop Computer
                            </li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                Desktop Computer
                            </li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                Printer
                            </li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                Electronics
                            </li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                Smart Home and Office
                            </li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                Personal Gadgets
                            </li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                Software
                            </li>

                            </ul>


                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section>

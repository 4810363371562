import { Component, OnInit } from "@angular/core";
import { Title,Meta } from "@angular/platform-browser";
@Component({
    selector: "app-careers",
    templateUrl: "./careers.component.html",
    styleUrls: ["./careers.component.scss"],
})
export class CareersComponent implements OnInit {
  title='Alina Infotech-Careers'
    constructor(private titleservice:Title,private meta:Meta) {
        this.titleservice.setTitle(this.title)
        this.meta.addTag({name:'author', content:'rsgitech'})
        this.meta.addTag({name:'robot', content:'index,follow'})
        this.meta.updateTag({name:'keyword',content:'Careers,Why work with us,Alina Infotech Careers'})
        this.meta.updateTag({name:'description',content:'Incentives and benifits of working with alina and position offered by alina'})
    }

    ngOnInit(): void {}
}

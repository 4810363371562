<section class="bg-half-100 d-table w-100" style="background: url('../../../../assets/img/background.svg') center center" id="home">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="title-heading mt-4">

                    <h2 class="heading mb-3"> <span class="text-primary">Experience,Dedication,</span> and professionalism to the IT    </h2>
                    <p class="para-desc">“We strive to delivery industry-leading IT hardware and software solution to the world at its
                        best affordability and convenient.”</p>
                    <div class="mt-4 pt-2">
                        <a routerLink="/about" class="btn btn-pills btn-primary m-1">Read More..</a>
                         </div>
                </div>
            </div><!--end col-->

            <div class="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <img src="../../../../assets/img/digital-workplace.png" class="img-fluid" alt="">
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section>
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title ">
                    <h4 class="title mb-4">What We Do ?</h4>
                    <p class="text-muted para-desc mx-auto mb-0"> <span class="text-primary fw-bold">Alina Infotech Pvt. Ltd.</span>  Deals with various wide range of IT related Products and Services under
                        reasonable price. Since the start of our service, we are actively providing our products and service
                        in different sectors like retail, corporates, government office, NGO’s, INGO’s etc. Some of the
                        Products and Services we deal with are as below</p>
                </div>
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-md-4 col-12 mt-5 pt-4">
                <div class="features feature-primary">
                    <div class="image position-relative d-inline-block">
                        <figure>
                            <img
                                src="../../../../assets/img/commu.png"
                                class="img-fig"
                                alt="suktaserpimg"
                                id="home-icons"
                            />
                        </figure>
                    </div>
                    <div class="content mt-4">
                        <h5>ICT CONSULTANCY</h5>
                        <p class="text-muted mb-0">We consult for high-end technology implementation at clients’ site or implementation of new
                            technology .</p>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-md-4 col-12 mt-5 pt-4">
                <div class="features feature-primary">
                    <div class="image position-relative d-inline-block">
                        <figure>
                            <img
                                src="../../../../assets/img/web-development.png"
                                class="img-fig"
                                alt="suktaserpimg"
                                id="home-icons"
                            />
                        </figure>
                    </div>
                    <div class="content mt-4">
                        <h5>SOFTWARE  DEVELOPMENT</h5>
                        <p class="text-muted mb-0">Developing software, related to production management,
                            commercial jobs, buying, accounting software for trading, manufacturing house.</p>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-md-4 col-12 mt-5 pt-4">
                <div class="features feature-primary">
                    <div class="image position-relative d-inline-block">
                        <figure>
                            <img
                                src="../../../../assets/img/network11.jpg"
                                class="img-fig"
                                alt="suktaserpimg"
                                id="home-icons"
                            />
                        </figure>
                    </div>
                    <div class="content mt-4">
                        <h5>NETWORK    &amp; INFRASTRUCTURE SERVICES</h5>
                        <p class="text-muted mb-0">Alina's NIS services enable clients to identify, develop, and implement the best-fit solutions
                            which are equipped to meet their changing business requirements.</p>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-md-4 col-12 mt-5 pt-4">
                <div class="features feature-primary">
                    <div class="image position-relative d-inline-block">
                        <figure>
                            <img
                                src="../../../../assets/img/hardware.png"
                                class="img-fig"
                                alt="suktaserpimg"
                                id="home-icons"
                            />
                        </figure>
                    </div>

                    <div class="content mt-4">
                        <h5>HARDWARE SALES </h5>
                        <p class="text-muted mb-0">We offer servers, computers, computer accessories and services by sourcing from local market and
                            from international market as well.</p>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-md-4 col-12 mt-5 pt-4">
                <div class="features feature-primary">
                    <div class="image position-relative d-inline-block">
                        <figure>
                            <img
                                src="../../../../assets/img/support1.png"
                                class="img-fig"
                                alt="suktaserpimg"
                                id="home-icons"
                            />
                        </figure>
                    </div>

                    <div class="content mt-4">
                        <h5>SUPPORT SERVICES</h5>
                        <p class="text-muted mb-0">Our team of experts is ready to serve you when you are worried
                            due to lack of confidence in “commitment of service”</p>
                    </div>
                </div>
            </div><!--end col-->

            <div class="col-md-4 col-12 mt-5 pt-4">
                <div class="features feature-primary">
                    <div class="image position-relative d-inline-block">
                        <figure>
                            <img
                                src="../../../../assets/img/project manager.png"
                                class="img-fig"
                                alt="suktaserpimg"
                                id="home-icons"
                            />
                        </figure>
                    </div>

                    <div class="content mt-4">
                        <h5>PROJECT MANAGEMENT</h5>
                        <p class="text-muted mb-0">Our
                            strength lies in our ability to blend current management practice and IT expertise into cost-effective
                            Computer Aided Management Solutions, Products and Services.</p>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section>


<!-- our services start -->
<div class="section bg-light">
    <div class="container " id="services">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">Areas of Expertise</h4>
                    <p class="text-muted para-desc mx-auto mb-0">
                        Main Strength of Alina Infotech lies in the blend of professionals, specialized and highly focused
    operation. Increasing customer’s awareness is the strength where it excels over its competitors. Our
    strength lies in our ability to blend current management practice and IT expertise into cost-effective
    Computer Aided Management Solutions, Products and Services.
                    </p>
                </div>
            </div>
            <!--end col-->
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div
                    class="d-flex features feature-primary key-feature align-items-center p-3 rounded-pill shadow"
                >
                    <div class="icon text-center rounded-circle me-3">
                        <img
                            src="../../../../assets/img/shopping-bag.svg"
                            alt="ecommerce"
                        />
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">System analysis                    </h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div
                    class="d-flex features feature-primary key-feature align-items-center p-3 rounded-pill shadow"
                >
                    <div class="icon text-center rounded-circle me-3">
                        <img
                            src="../../../../assets/img/command (1).svg"
                            alt="erp"
                        />
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">Business process reengineering</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div
                    class="d-flex features feature-primary key-feature align-items-center p-3 rounded-pill shadow"
                >
                    <div class="icon text-center rounded-circle me-3">
                        <img
                            src="../../../../assets/img/monitor (3).svg"
                            alt="web development"
                        />
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">Hardware at competitive price</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div
                    class="d-flex features feature-primary key-feature align-items-center p-3 rounded-pill shadow"
                >
                    <div class="icon text-center rounded-circle me-3">
                        <img
                            src="../../../../assets/img/smartphone (3).svg"
                            alt="mobile development"
                        />
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">E-business solutions</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div
                    class="d-flex features feature-primary key-feature align-items-center p-3 rounded-pill shadow"
                >
                    <div class="icon text-center rounded-circle me-3">
                        <img
                            src="../../../../assets/img/cpu.svg"
                            alt="Artificial Intelligence"
                        />
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">Artificial Intelligence</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div
                    class="d-flex features feature-primary key-feature align-items-center p-3 rounded-pill shadow"
                >
                    <div class="icon text-center rounded-circle me-3">
                        <img
                            src="../../../../assets/img/link.svg"
                            alt="blockchain"
                        />
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">Network
                            Management Support </h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div
                    class="d-flex features feature-primary key-feature align-items-center p-3 rounded-pill shadow"
                >
                    <div class="icon text-center rounded-circle me-3">
                        <img
                            src="../../../../assets/img/user (1).svg"
                            alt="It mentorship"
                        />
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">Enterprise Server & Storage Solution</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div
                    class="d-flex features feature-primary key-feature align-items-center p-3 rounded-pill shadow"
                >
                    <div class="icon text-center rounded-circle me-3">
                        <img
                            src="../../../../assets/img/cloud-rain.svg"
                            alt="cloud backup"
                        />
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">Cloud Backup services</h4>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
                <div
                    class="d-flex features feature-primary key-feature align-items-center p-3 rounded-pill shadow"
                >
                    <div class="icon text-center rounded-circle me-3">
                        <img
                            src="../../../../assets/img/phone-call.svg"
                            alt="Remote support"
                        />
                    </div>
                    <div class="flex-1">
                        <h4 class="title mb-0">Remote support</h4>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
    </div>
</div>


<!--our services end-->


<section class="section bg-cta " style="background: url('../../../../assets/img/ourcoverage.png') center center;background-repeat: no-repeat;background-size: contain;height: 400px;">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title">
                    <h4 class="title title-dark text-white mb-4">Our Coverage</h4>
                    <p class="text-white-50 para-dark para-desc mx-auto"> With Three Branches and Service partners in different location we cover a huge
                        area of sales and services within Nepal. We are expanding our branch in the future
                        to increase our presence for sales and services. For now, we can see our presence
                        and areas covered in following map.</p>

                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div><!--end container-->
</section>


<!--Alina History-->
<div class="container mt-100 mt-60" id="history">
    <div class="row justify-content-center">
        <div class="col-12">
            <div class="section-title text-center mb-4 pb-2">
                <h4 class="title mb-4">Alina History</h4>
                <p class="text-muted para-desc mx-auto mb-0">
                    Alina has more then a decade of experience in providing tech related
                    solutions to all the sectors of society along with the
                    reputation of being best tech company out there.
                </p>
            </div>
        </div>
        <!--end col-->
    </div>
    <!--end row-->

    <div class="row" id="counter">
        <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
            <div
                class="card features feature-primary explore-feature border-0 shadow rounded text-center"
            >
                <div class="card-body">
                    <div
                        class="icons rounded-circle shadow-lg h4 d-inline-block"
                    >
                        <img
                            src="../../../../assets/img/award.svg"
                            alt="completed projects"
                        />
                    </div>
                    <div class="content mt-3">
                        <h4 class="mb-0">
                            <span class="counter-value" data-target="858"
                                >500</span
                            >+
                        </h4>
                        <h6 class="mb-0 text-muted">Completed Projects</h6>
                    </div>
                </div>
            </div>
        </div>
        <!--end col-->

        <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
            <div
                class="card features feature-primary explore-feature border-0 shadow rounded text-center"
            >
                <div class="card-body">
                    <div
                        class="icons rounded-circle shadow-lg h4 d-inline-block"
                    >
                        <img
                            src="../../../../assets/img/layers.svg"
                            alt="years of service"
                        />
                    </div>
                    <div class="content mt-3">
                        <h4 class="mb-0">
                            <span class="counter-value" data-target="445"
                                >15</span
                            >
                            +
                        </h4>
                        <h6 class="mb-0 text-muted">Years of service</h6>
                    </div>
                </div>
            </div>
        </div>
        <!--end col-->

        <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
            <div
                class="card features feature-primary explore-feature border-0 shadow rounded text-center"
            >
                <div class="card-body">
                    <div
                        class="icons rounded-circle shadow-lg h4 d-inline-block"
                    >
                        <img
                            src="../../../../assets/img/users (2).svg"
                            alt="completed projects"
                        />
                    </div>
                    <div class="content mt-3">
                        <h4 class="mb-0">
                            <span class="counter-value" data-target="1154"
                                >250</span
                            >+
                        </h4>
                        <h6 class="mb-0 text-muted">Professional Employee's</h6>
                    </div>
                </div>
            </div>
        </div>
        <!--end col-->

        <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
            <div
                class="card features feature-primary explore-feature border-0 shadow rounded text-center"
            >
                <div class="card-body">
                    <div
                        class="icons rounded-circle shadow-lg h4 d-inline-block"
                    >
                        <img
                            src="../../../../assets/img/smile.svg"
                            alt="Customer Satisfaction"
                        />
                    </div>
                    <div class="content mt-3">
                        <h4 class="mb-0">
                            <span class="counter-value" data-target="111"
                                >98</span
                            >
                            %
                        </h4>
                        <h6 class="mb-0 text-muted">Customer Satisfaction</h6>
                    </div>
                </div>
            </div>
        </div>
        <!--end col-->
    </div>
    <!--end row-->
</div>
<!--Alina history-->
<!--Alina mission and vision start-->
<div class="container mt-100 mt-60" id="vision">
    <div class="row align-items-center">
        <div class="col-lg-5 col-md-6">
            <img
                src="../../../../assets/img/interactiondesign-amico.svg"
                class="img-fluid"
                alt=""
            />
        </div>
        <!--end col-->

        <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div class="section-title ms-lg-5">
                <h4 class="title mb-4">Alina vision and mission</h4>

                <h6>
                    <i class="uil uil-arrow-circle-right text-primary me-1"></i>
                    We strive to delivery industry-leading IT hardware and software solution to the world at its
best affordability and convenient.
                </h6>
                <h6>
                    <i class="uil uil-arrow-circle-right text-primary me-1"></i>
                    The vision at Alina Infotech, is of a highly trained and efficient team of Support and customer
                    service personnel, ready for anything that may occur and quick to respond to customer needs and
                    concerns.

                </h6>
                <h6>
                    <i class="uil uil-arrow-circle-right text-primary me-1"></i>
                    We believe that these values set us a step above the rest,
                    and allow our team members to provide high quality product and services in a very professional
                    and efficient manner.

                </h6>

            </div>
        </div>
        <!--end col-->
    </div>
    <!--end row-->
</div>
<!--Alina mission and vision end-->

